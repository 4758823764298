@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "PoppinsRegular";
  src: url("./Fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "PoppinsBlack";
  src: url("./Fonts/Poppins-Black.ttf") format("truetype");
}
@font-face {
  font-family: "PoppinsBold";
  src: url("./Fonts/Poppins-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "PoppinsLight";
  src: url("./Fonts/Poppins-Light.ttf") format("truetype");
}
@font-face {
  font-family: "PoppinsMedium";
  src: url("./Fonts/Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "PoppinsThin";
  src: url("./Fonts/Poppins-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "Helvetica";
  src: url("./Fonts/helvetica_neu_bold.ttf") format("truetype");
}
@font-face {
  font-family: "Manrope";
  src: url("./Fonts/Manrope/static/Manrope-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Italiana";
  src: url("./Fonts/Italiana/Italiana-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Cervanttis";
  src: url("./Fonts/cervanttis/Cervanttis.ttf") format("truetype");
}

:root {
  --tw-skew-x: 20;
  --cbpo1-width: 30px;
  --cbpo3-ml: 0%;
  --navbar-width: 0vw;
  --flip-card-bg-colour: yellow;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

* {
  padding: 0px;
  margin: 0px;
}

::-webkit-scrollbar {
  height: 0px; /* height of horizontal scrollbar ← You're missing this */
  width: 5px; /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}
::-webkit-scrollbar-track {
  border-radius: 80px;
  background: #5f6469;
}

::-webkit-scrollbar-thumb {
  border-radius: 80px;
  background: #b0b0b0;
}

.top-display {
  background-image: url("./Images/background.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: right;
}

/* HTML: <div class="loader"></div> */
.loader {
  /* width: 60px; */
  aspect-ratio: 1;
  border: 5px solid #ddd;
  border-radius: 50%;
  position: relative;
  transform: rotate(45deg);
}
.loader::before {
  content: "";
  position: absolute;
  inset: -6px;
  border-radius: 50%;
  border: 7px solid #514b82;
  animation: l18 8s infinite linear;
}
@keyframes l18 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

.main-loader {
  position: relative;
  width: 20px; /* 100px */
  height: 20px; /* 100px */
}

.main-loader:before,
.main-loader:after {
  content: "";
  border-radius: 50%;
  position: absolute;
  inset: 0;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3) inset;
}
.main-loader:after {
  box-shadow: 0 2px 0 #ff3d00 inset;
  animation: rotate 5s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.cbp01 {
  width: var(--cbpo1-width);
  animation: cbp01-slide-in 1s ease-in;
  overflow: hidden;
}

.cbp02 {
  width: 0px;
  animation: cbp01-slide-out 1.5s ease-in;
  overflow: hidden;
}

@keyframes cbp01-slide-in {
  from {
    width: 0px;
  }

  to {
    width: var(--cbpo1-width);
  }
}

@keyframes cbp01-slide-out {
  from {
    width: var(--cbpo1-width);
  }

  to {
    width: 0px;
  }
}

.cbp03 {
  margin-left: var(--cbpo3-ml);
  animation: cbp03-slide-in 1s ease-in;
  overflow: hidden;
}

@keyframes cbp03-slide-in {
  from {
    margin-left: 100%;
    margin-right: 0px;
  }

  to {
    margin-left: var(--cbpo3-ml);
    margin-right: auto;
  }
}

.cbp04 {
  margin-left: var(--cbpo3-ml);
  animation: cbp04-slide-in 1.5s ease-in;
  overflow: hidden;
}

@keyframes cbp04-slide-in {
  from {
    margin-left: 100%;
    margin-right: 0px;
  }

  to {
    margin-left: var(--cbpo3-ml);
    margin-right: auto;
  }
}

.cbp05 {
  margin-left: var(--cbpo3-ml);
  animation: cbp05-slide-in 2s ease-in;
  overflow: hidden;
}

@keyframes cbp05-slide-in {
  from {
    margin-left: 100%;
    margin-right: 0px;
  }

  to {
    margin-left: var(--cbpo3-ml);
    margin-right: auto;
  }
}

.cbp06 {
  margin-left: 100%;
  animation: cbp06-slide-out 2s ease-in;
  overflow: hidden;
}

@keyframes cbp06-slide-out {
  from {
    margin-left: var(--cbpo3-ml);
    margin-right: auto;
  }

  to {
    margin-left: 100%;
    margin-right: 0px;
  }
}

.cbp07 {
  margin-left: 100%;
  animation: cbp07-slide-out 1.5s ease-in;
  overflow: hidden;
}

@keyframes cbp07-slide-out {
  from {
    margin-left: var(--cbpo3-ml);
    margin-right: auto;
  }

  to {
    margin-left: 100%;
    margin-right: 0px;
  }
}

.cbp08 {
  margin-left: 100%;
  animation: cbp08-slide-out 1s ease-in;
  overflow: hidden;
}

@keyframes cbp08-slide-out {
  from {
    margin-left: var(--cbpo3-ml);
    margin-right: auto;
  }

  to {
    margin-left: 100%;
    margin-right: 0px;
  }
}

.glacier {
  background: url(/src//Images/elon-musk.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  /* background-color: rgba(70, 70, 70, 0.6); */
  max-width: 100%;
  width: 100vw;
  /* height: 650px; */
  padding-left: 0px;
  padding-right: 0px;
  background-attachment: fixed;
  /* opacity: 0.2; */
}

.glacier > div {
  background-color: rgba(70, 70, 70, 0.6);
}

.slider-news {
  width: 90vw !important;
  height: 480px !important;
  margin: 8px 5vw !important;
}

.connect-with-us {
  position: relative;
  /* height: 100vh; */
  z-index: 1;
}

.connect-with-us::before {
  background-image: url("./Images/crowd.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 100vw;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  z-index: 0;
}

.connect-with-us::after {
  content: "";
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the color and opacity as needed */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Place the overlay on top of the background image */
}

.option {
  color: rgb(173, 175, 177) !important;
}

@media screen and (min-width: 768px) {
  .slider-news {
    width: 47vw !important;
    height: 480px !important;
    margin: 8px 1vw !important;
  }
}

@media screen and (min-width: 1024px) {
  .slider-news {
    width: 21vw !important;
    height: 480px !important;
    margin: 8px 1vw !important;
  }
}

.navbar1 {
  width: var(--navbar-width);
  animation: navbar1-slide-in 0.3s ease-in;
  overflow: hidden;
}

.navbar2 {
  width: 0vw;
  animation: navbar2-slide-out 0.2s ease-in;
  overflow: hidden;
}

@keyframes navbar1-slide-in {
  from {
    width: 0vw;
  }

  to {
    width: var(--navbar-width);
  }
}

@keyframes navbar2-slide-out {
  from {
    width: var(--navbar-width);
  }

  to {
    width: 0vw;
  }
}

.arrow-rotate {
  transform: rotateZ(90deg);
  transition: transform 0.6s ease-in;
}

.reverse-rotate {
  transform: rotateZ(0deg);
  transition: transform 0.5s ease-in;
}

.open-height {
  height: 220px;
  animation: open-height-anim 0.6s ease-in;
}

.close-height {
  height: 0px;
  animation: close-height-anim 0.5s ease-in;
}

@keyframes open-height-anim {
  from {
    height: 0px;
  }

  to {
    height: 220px;
  }
}

@keyframes close-height-anim {
  from {
    height: 220px;
  }

  to {
    height: 0px;
  }
}

.slide-up {
  top: 27%;
  animation: slide-up-anim 500ms ease-in;
}

.slide-up-text {
  top: 27%;
  animation: slide-up-anim 650ms ease-in;
}

@keyframes slide-up-anim {
  from {
    top: 100%;
    opacity: 0;
  }

  to {
    top: 27%;
    opacity: 1;
  }
}

.slide-up-text-off {
  top: -27%;
  opacity: 0;
  animation: slide-up-anim-off 1.5s ease-in;
}

.slide-up-off {
  top: -20%;
  opacity: 0;
  animation: slide-up-anim-off 1s ease-out;
}

@keyframes slide-up-anim-off {
  0% {
    top: 27%;
    opacity: 1;
  }

  100% {
    top: -20%;
    opacity: 0;
  }
}

/* slide down */
.slide-down {
  top: 37%;
  animation: slide-down-anim 500ms ease-in;
}

.slide-down-text {
  top: 37%;
  animation: slide-down-anim 650ms ease-in;
}

@keyframes slide-down-anim {
  from {
    top: -20%;
    opacity: 0;
  }

  to {
    top: 37%;
    opacity: 1;
  }
}

.slide-down-text-off {
  top: -27%;
  opacity: 0;
  animation: slide-down-anim-off 1.2s ease-in;
}

.slide-down-off {
  top: 100%;
  opacity: 0;
  animation: slide-down-anim-off 1s ease-out;
}

@keyframes slide-down-anim-off {
  0% {
    top: 37%;
    opacity: 1;
  }

  100% {
    top: 100%;
    opacity: 0;
  }
}

.flip-card {
  background-color: transparent;
  /* width: 244px !important;
  height: 280px !important; */
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: var(--flip-card-bg-colour);
  /* color: black; */
}

.flip-card-back {
  background-color: var(--flip-card-bg-colour);
  color: white;
  transform: rotateY(180deg);
}

.header-minH {
  height: 10vh;
  /* border: 2px solid red; */
  transition: height 600ms ease-in-out;
}

.header-maxH {
  height: 20vh;

  transition: height 600ms ease-in-out;
}

.show-details {
  max-height: 1000px; /* Set a large enough value to ensure that all content is visible */
  /* transition: max-height 0.8s ease-in; Smoothly transition max-height property */
  animation: show-details 1400ms ease-out;
}

@keyframes show-details {
  from {
    max-height: 0;
  }
  to {
    max-height: 1000px;
  }
}

.hide-details {
  max-height: 0;
  overflow: hidden;
  padding: 0px 3vw;
  /* transition: max-height 1.3s ease-in, padding 1.3s ease-in; */
  animation: hide-details 100ms ease-in;
}

@keyframes hide-details {
  from {
    max-height: 1000px;
    padding: 12px 3vw;
  }
  to {
    max-height: 0;
    padding: 0px 3vw;
  }
}

.slider-news-v2 {
  width: 28.6vw !important;
  height: fit-content !important;
  margin: 8px 3vw !important;
}
@media screen and (min-width: 768px) {
  .slider-news-v2 {
    width: 28vw !important;
    height: fit-content !important;
    margin: 8px 1vw !important;
  }
}

@media screen and (min-width: 1024px) {
  .slider-news-v2 {
    width: 22.6vw !important;
    height: fit-content !important;
    margin: 8px 1vw !important;
  }
}

@media screen and (min-width: 1440px) {
  .slider-news-v2 {
    width: 11.3vw !important;
    height: fit-content !important;
    margin: 8px 1vw !important;
  }
}

.cyberpack_video-bg {
  background-image: url("./Images/meet-team-video-globe-background.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: right;
}

.oval-bottom {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 100vh; */
  width: 100vw;
  background-color: #087cb7;
  overflow-x: hidden;
}

.custom-shape-divider-bottom-1715764172 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 140vw;
  overflow: hidden;
  line-height: 0;
  margin-left: -20vw;
}

.custom-shape-divider-bottom-1715764172 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  /* height: 70vh; */
}

.custom-shape-divider-bottom-1715764172 .shape-fill {
  fill: rgb(240, 248, 255); /** #ffffff;*/
}

.ux-card {
  box-shadow: -2px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: rgba(255, 255, 255, 0.3);
}

.gif-container {
  position: relative;
  display: inline-block; /* or inline-flex or block based on your layout */
}

.color-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(255, 255, 255); */
  z-index: 1; /* Make sure the overlay is above the GIF */
}

.curved-div {
  background-image: url("./Images/phoneMainTop.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: right;
}

.curved-div-2 {
  background-image: url("./Images/case-studies-background.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: right;
}

@media screen and (min-width: 768px) {
  .curved-div {
    background-image: url("./Images/maintopcurve.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: right;
  }
}

.hide-img-details {
  clip-path: polygon(0% 14%, 100% 14%, 100% 100%, 0% 100%);
  border: 3px solid orangered;
}

.strategy-card {
  display: flex !important;
  flex-direction: column !important;
  position: relative;
}

@media screen and (min-width: 768px) {
  .strategy-card {
    display: flex !important;
    flex-direction: row !important;
    position: relative;
    /* margin-left: 2vw !important;*/
    margin-right: 2vw !important;
  }
}

.ux-case-studies {
  border-radius: 74% 26% 44% 56% / 55% 58% 42% 45%;
}

.clipped-image {
  width: 300px; /* Set the desired width */
  height: auto; /* Maintain aspect ratio */
  clip-path: polygon(0% 20%, 100% 20%, 100% 100%, 0% 100%);
  /* The 20% value represents the portion of the top you want to hide */
}

.custom-shape-divider-top-1716480124 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1716480124 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 150px;
}

.custom-shape-divider-top-1716480124 .shape-fill {
  fill: rgb(240, 248, 255);
}

#e16XPYsai9b17 {
  transition: transform 1s;
}

.translate-test {
  transform: translate(-697.418091px, -36.429108px);
}
